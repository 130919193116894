import { getStaticAssetPath } from '../../utils/assets';

export function SocialProofJennifer() {
  return (
    <div className='w-60 bg-white text-black rounded-2.5xl px-5 py-4 flex flex-col items-center gap-3'>
      <div className='w-full flex items-center gap-2'>
        <img
          src={getStaticAssetPath(
            'images/OneTimePurchase/social-proof-jennifer-paxton.png'
          )}
          alt='avatar'
          className='rounded-full w-12 h-12 object-contain bg-black'
        />
        <div>
          <div className='text-2xs font-bold'>Jennifer Paxton</div>
          <div className='mt-0.5 text-3xs italic w-35'>
            Senior Director of People and Talent
          </div>
        </div>
      </div>
      <div className='w-full text-sms'>
        We've tried everything and Luna Park is the most reliable and effective
        way to build trust and empathy across your team. It's amazingly unique
        and fun too!
      </div>
      <img
        src={getStaticAssetPath(
          'images/OneTimePurchase/social-proof-roofr.png'
        )}
        alt='logo'
        className='w-20 h-5.5 object-contain'
      />
    </div>
  );
}
